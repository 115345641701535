import * as moment from "moment";
import axios from "axios";
import { links } from "@/helpers/variables/links";
import { translatePeriods } from "@/helpers/variables/translateString";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants";
import { subscriptionPlans } from "@/helpers/variables/subscriptionPlans";
import VueJwtDecode from "vue-jwt-decode";

moment.locale("pt-br");

export function sortByLatest(array, key) {
  return array.sort(
    (a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()
  );
}

export function sortByOldest(array, key) {
  return array.sort(
    (a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()
  );
}

export function sortTemplatesByPopular(array) {
  return array.sort((a, b) => b.is_popular - a.is_popular);
}

export function sortByName(array) {
  // Precisa do slice() porque o sort() altera o próprio array
  return array.slice().sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
}

export const today = moment().utc().locale("pt-br");

export const todayDataBaseFormat = moment().utc().format("YYYY-MM-DD");

export const formatModuleName = (data) => {
  return data.replace("_", " ");
};

export function formatDateFullMonth(date, format = "YYYY-MM-DD") {
  if (!date) {
    return "";
  }

  let dateToFormat = moment(date);
  dateToFormat.format(format);
  moment.locale("pt-br");
  return dateToFormat.format("DD [ de ] MMM[. de ] YYYY");
}

export function formatDateWithDayOfWeek(date) {
  let dateToFormat = moment(date).utc();
  return dateToFormat.format("ddd[.,] DD [ de ] MMM[. de ] YYYY");
}

export function formatMoney(value) {
  value = parseFloat(value);

  if (isNaN(value)) {
    return value;
  }
  const formatter = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function formatDate(date) {
  if (!date) return null;

  return moment.utc(date).format("DD/MM/YYYY");
}

export function formatDateForDatabase(date) {
  if (!date) return null;

  return moment.utc(date).format("YYYY-MM-DD");
}

export function getTheEndOfTrial() {
  let endOfTrial = moment();
  endOfTrial.add(14, "day");
  return endOfTrial;
}

export function getStartOfBilling() {
  let endOfTrial = moment(new Date());
  endOfTrial.add(14, "day");
  return endOfTrial;
}

export function momentLocaleUtc(date) {
  return moment.utc(date).locale("pt-br").format("L");
}

export function formatDateAndHour(date, without_time_zone = false) {
  if (!date) return "Não encontrado";
  let hour = moment.utc(date).locale("pt-br").format("LT");
  let day = moment.utc(date).locale("pt-br").format("L");

  if (without_time_zone) {
    hour = moment(date).locale("pt-br").format("LT");
    day = moment(date).locale("pt-br").format("L");
  }

  if (hour.slice(0, 2) == "00" || hour.slice(0, 2) == "01") {
    return `${day} à ${hour}`;
  }
  return `${day} às ${hour}`;
}

export function checkExpiredDate(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck < today;
}

export function formatDateIntoStringYearMonthDay(date) {
  let year = date.getUTCFullYear();
  let month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  let day = ("0" + date.getUTCDate()).slice(-2);

  let formattedDate = year + "-" + month + "-" + day;

  return formattedDate;
}

export function formatDateIntoStringDayMonthYear(date) {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  } else return "--/--/----";
}

export function isExpiredTrial(date) {
  return checkIfIsGoingToExpire(date) ? "TRIAL" : "EXPIRED";
}

export function checkIfItsNotToday(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck > today;
}

export function checkExpiresToday(date) {
  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck == today;
}

export function checkIfIsGoingToExpire(date) {
  if (!date) return false;

  let today = moment().locale("pt-br").format("YYYY-MM-DD");
  let dateToCheck = moment(date).format("YYYY-MM-DD");
  return dateToCheck > today;
}

export function greaterOrEqualToday(date) {
  if (!date) return false;

  const today = moment().locale("pt-br").format("YYYY-MM-DD");
  const dateToCheck = moment(date).format("YYYY-MM-DD");

  return dateToCheck >= today;
}

export function getRemainingTrialDays(end_trial) {
  moment().locale("pt-br");

  const endTrialDate = moment(end_trial);

  const diffDate = endTrialDate.diff(moment().startOf("day"), "days");

  if (endTrialDate.isAfter(moment(), "day")) {
    return `${
      diffDate === 1
        ? diffDate + " dia de teste restante"
        : diffDate + " dias de teste restantes"
    }`;
  } else if (endTrialDate.isSame(moment(), "day")) {
    return "Último dia de teste!";
  } else if (endTrialDate.isBefore(today)) {
    return `${
      diffDate === -1
        ? "Expirado há " + diffDate + " dia"
        : "Expirado há " + diffDate + " dias"
    } `;
  } else {
    return false;
  }
}

export function getExpiredTrialDays(end_trial) {
  moment().locale("pt-br");

  const endTrialDate = moment(end_trial);

  const diffDate = endTrialDate.diff(moment().startOf("day"), "days");

  if (endTrialDate.isBefore(moment(), "day")) {
    return `${
      diffDate === 1
        ? "Expirado há " + diffDate + " dia"
        : "Expirado há " + diffDate + " dias"
    } `;
  } else {
    return false;
  }
}

export function checkIfDatesAreEqual(date1, date2) {
  const firstDate = moment(date1).format("YYYY-MM-DD");
  const secondDate = moment(date2).format("YYYY-MM-DD");
  return moment(firstDate).isSame(moment(secondDate));
}

export function addDays(date, days) {
  let dateToAdd = moment(date);
  return dateToAdd.add(days, "d");
}

export function addDaysFormat(days) {
  return moment().add(days, "days").format("YYYY-MM-DD");
}

export function removeDays(date, days) {
  return moment(date).subtract(days, "d");
}

export function addMonths(date, months) {
  let dateToAdd = moment(date);
  return dateToAdd.add(months, "M");
}

export function addYears(date, years) {
  let dateToAdd = moment(date);
  return dateToAdd.add(years, "Y");
}

export function dateIsAfterAnother(firstDate, secondDate) {
  return moment(firstDate).isAfter(moment(secondDate));
}

export function dateIsToday(date) {
  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck == today.format("YYYY-MM-DD");
}

export function organizeDate(date1, date2) {
  if (!date1) return null;

  if (date2 != null && date2 != "") {
    if (date1 <= date2) {
      date1 = this.formatDate(date1);
      date2 = this.formatDate(date2);
    } else {
      let interval = [date2, date1];
      date1 = this.formatDate(interval[0]);
      date2 = this.formatDate(interval[1]);
      this.timeInterval = interval;
    }
    return date1 + " - " + date2;
  } else {
    date1 = this.formatDate(date1);
    return date1;
  }
}

export function formatPeriod(initDate, endDate) {
  initDate = moment(initDate).format("MM/DD/YYYY");
  const initDateFormated = moment(initDate).locale("pt-br").format("ll");

  if (endDate) {
    endDate = moment(endDate).format("MM/DD/YYYY");
    const endDateFormated = moment(endDate).locale("pt-br").format("ll");
    if (initDateFormated === endDateFormated) {
      return initDateFormated;
    }
    return `${initDateFormated} - ${endDateFormated}`;
  }
  return initDateFormated;
}

export function moveScreenToUp() {
  window.scrollTo(0, 0);
}

export function formatPlanMoney(subscriptionType, price) {
  const coefficientPeriods = {
    FLEX: 1,
    BIENNIUM: 24,
    ANNUAL: 12,
    SEMESTER: 6,
    QUARTELY: 3,
  };

  let finalPrice = price * coefficientPeriods[subscriptionType];

  return `${formatMoney(finalPrice)}/${
    translatePeriods[subscriptionType]
  } por licença`;
}

export function formatPlanName(companyPlan) {
  let subscriptionType = companyPlan.subscription_type;
  let periods = {
    FLEX: "Flex",
    ANNUAL: "Anual",
    SEMESTER: "Semestral",
    QUARTELY: "Trimestral",
    BIENNIUM: "Bienal",
  };
  return `${companyPlan.plan.name} - ${periods[subscriptionType]}`;
}

export function decimalCount(num) {
  const numStr = num.toString();
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  return 0;
}

export function hasDecimal(num) {
  const numStr = num.toString();
  if (numStr.includes(".")) {
    return parseInt(numStr.split(".")[1]) > 0;
  }
  return false;
}

export function isErrorToRedirectToErrorPage(message_status) {
  const ERRORS_MESSAGE = ["YOU_ARE_NOT_A_RESELLER_FOR_THIS_ACCOUNT"];
  return ERRORS_MESSAGE.includes(message_status);
}

export function redirectToErrorPage() {
  window.location.href = "/error";
}

export function dotsToComma(num, returnValue = "") {
  return num ? num.toString().replace(".", ",") : returnValue;
}

export function formatNumberToPercent(num) {
  return (num * 100).toFixed(2) + "%";
}

export const rulePhoneNumber = [
  (v) =>
    !v.length ||
    /^[\d ()+-]+$/.test(v) ||
    "Por favor, insira um número de telefone válido",
  (v) => v.length <= 20 || "Máximo de 20 caracteres",
];

export const requiredPhoneNumber = [
  (v) =>
    (v !== null && /^[\d ()+-]+$/.test(v)) ||
    "Por favor, insira um número de telefone válido",
];

export function maskCNPJ(cnpj) {
  if (cnpj) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  return "";
}

export function maskCEP(cep) {
  if (cep) {
    return cep.replace(/^(\d{5})(\d{3})/, "$1-$2");
  } else {
    return "";
  }
}

export const moneyOptions = {
  locale: "pt-BR",
  prefix: "R$",
  suffix: "",
  precision: 2,
};

export function hasTagInText(text) {
  const regex = /\[\*(.*?)\*\]/g;
  return !!text.match(regex);
}

export function ticketExpiration(next_billing, subscription_type) {
  const nextBilling = moment(next_billing, "DD/MM/YYYY");

  if (subscription_type === SUBSCRIPTIONS.ANNUAL) {
    return formatDate(
      nextBilling.add(subscriptionPlans.ANNUAL.ticketExpiration, "d")
    );
  } else {
    return formatDate(
      nextBilling
        .add(1, "M")
        .set("date", subscriptionPlans.FLEX.ticketExpiration)
    );
  }
}

export function formatDateToUnix(date) {
  return moment(date).unix();
}

export function openInNewTab(url) {
  window.open(url, "_blank", "noreferrer");
}

export function openLink(url) {
  window.open(url);
}

export function goToControl() {
  window.open(process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL, "_blank");
}

export function goToDriveReport() {
  window.open(
    `${process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL}/reports/folders-sharing`,
    "_blank"
  );
}

export function subtractDays(days) {
  return moment().subtract(days, "days").format("YYYY-MM-DD");
}

export function subtractMonths(days) {
  return moment().subtract(days, "months").format("YYYY-MM-DD");
}

export function isTokenExpired(token) {
  if (!token) return true;

  const { exp } = VueJwtDecode.decode(token);
  return Date.now() >= exp * 1000;
}

export function initFreshChatGlobal(
  currentUser,
  companyFreshChatConfig,
  hasConectaSupport
) {
  let { responsible_email, domain } = companyFreshChatConfig;

  if (hasConectaSupport) {
    window.fcWidget.init({
      token: "95dd279d-3dcf-470e-87c2-5807bdc16c9a",
      host: "https://wchat.freshchat.com",
      open: true,
      externalId: domain,
      firstName: currentUser.name || currentUser.email || responsible_email,
      email: currentUser.email || responsible_email,
    });
    window.fcWidget.on("widget:closed", () => {
      window.fcWidget.destroy();
    });
  }
}

export function getObjectKeys(object) {
  if (!object) return [];
  return Object.keys(object);
}

export function impactedTemplateIds(templates, modifiedTags) {
  const impactedTemplateIDs = [];

  templates.forEach((template) => {
    const regex = new RegExp(modifiedTags.join("|"), "gi");

    if (
      !impactedTemplateIDs.includes(template.id) &&
      regex.test(template.html)
    ) {
      impactedTemplateIDs.push(template);
    }
  });

  return impactedTemplateIDs;
}

export function removeMask(name = "", param = "") {
  if (!name || !param) {
    return "";
  }

  const camps = {
    cnpj: param.replace(/[^0-9]+/g, ""),
    address_postal_code: param.replace(/[^0-9]+/g, ""),
    phone: param.replace(/[^0-9]/g, ""),
  };
  return camps[name];
}

export function preValidateCNPJ(cnpj = "") {
  const cnpjLengthWithMask = 18;
  const cnpjLengthWithoutMask = 14;
  const invalidCnpjs = [
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];
  if (cnpj.length !== cnpjLengthWithMask) return false;

  cnpj = removeMask("cnpj", cnpj);

  if (cnpj.length !== cnpjLengthWithoutMask) return false;

  // Elimina CNPJs inválidos conhecidos
  if (invalidCnpjs.includes(cnpj)) {
    return false;
  }
  return true;
}

export function validateCnpj(cnpj = "") {
  if (!cnpj) {
    return false;
  }

  const cleanedCnpj = cnpj.replace(/\D/g, "");

  if (cleanedCnpj.length !== 14) {
    return false;
  }

  if (/^(\d)\1+$/.test(cleanedCnpj)) {
    return false;
  }

  let size = cleanedCnpj.length - 2;
  let numbers = cleanedCnpj.substring(0, size);
  const digits = cleanedCnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = cleanedCnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(1)) {
    return false;
  }

  return true;
}

export function getVacationDates() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const vacationStart = params.vacationStart;
  const vacationEnd = params.vacationEnd;

  return { vacationStart, vacationEnd };
}

export function validateEmail(email, errorText = null) {
  return /.+@.+\..+/.test(email) || errorText || "E-mail inválido";
}

export function getWhatsAppLink(whatsAppNumber) {
  const regex = /\d+/g;

  whatsAppNumber = String(whatsAppNumber).match(regex) || [];
  const whatsAppLink = `https://wa.me/${whatsAppNumber.join("")}`;

  return whatsAppLink;
}

export function hasDecimalPart(number) {
  return number - Math.floor(number) !== 0;
}

export function hasChanges(newVal, oldVal) {
  return JSON.stringify(newVal) !== JSON.stringify(oldVal);
}

export function getFlexEndTransaction(date) {
  return moment(date) ? moment(date).endOf("month") : null;
}

export function getAnnualEndTransaction(date) {
  return moment(date) ? moment(date).add(1, "year").subtract(1, "days") : null;
}

export function debounce(fn, delay) {
  let timeoutId = 0;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
}
/**
 * Insert an element in any position (index) of an array
 * @param {Array} array The array you need to put a new element
 * @param {*} new_element The new element you want to put inside the array
 * @param {Number} index The index inside of array you want to put the new element
 * @returns A array with the element in the exact position
 */
export function insertElementAtIndex(array, new_element, index) {
  return [...array.slice(0, index), new_element, ...array.slice(index)];
}

export async function sendScheduleEmail(clicked_from) {
  let url = process.env.VUE_APP_API_BASE_URL + "/company/schedule-interest";
  const token = localStorage.getItem("token");
  await axios.post(
    url,
    { clicked_from: clicked_from },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function redirectToTheCommercialManagerGoogleCalendar(clicked_from) {
  sendScheduleEmail(clicked_from);
  window.open(links.calendarSchedule, "_blank");
}

export function insertMinutes(minutes, datetime) {
  return datetime.add(minutes, "minutes");
}

export function setSignatureFeedbackPage(status = false) {
  if (!localStorage.getItem("signatureFeedbackPage")) {
    localStorage.setItem("signatureFeedbackPage", "true");
  } else {
    localStorage.setItem("signatureFeedbackPage", JSON.stringify(status));
  }
}

export function generateRandomPassword(length) {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numberChars = "0123456789";
  const symbolChars = "!@#$%^&*()_+";

  let password = "";

  // Adiciona pelo menos um caractere de cada categoria
  password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += symbolChars[Math.floor(Math.random() * symbolChars.length)];

  const remainingLength = length - 4;
  const charset = lowercaseChars + uppercaseChars + numberChars + symbolChars;

  // Adiciona caracteres aleatórios para preencher o restante da senha
  for (let i = 0; i < remainingLength; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}

export function convertBytesInMegas(bytes = 0) {
  return (bytes / 1024 / 1024).toFixed(1); // Bytes -> Kb -> Mb
}

export function renewFeedbackOnSecondTime(feed) {
  if (!feed) return false;
  else if (feed == "ask_feedback") return true;
  return checkExpiredDate(feed);
}

export function setNotShowFeedbackToday(feedback) {
  let userFeedbacks = JSON.parse(localStorage.getItem("userFeedbacks")) || {};
  userFeedbacks[feedback] = today;
  localStorage.setItem("userFeedbacks", JSON.stringify(userFeedbacks));
}

export function checkShowFeedback(feedback) {
  let userFeedbacks = JSON.parse(localStorage.getItem("userFeedbacks"));
  if (!userFeedbacks || !userFeedbacks[feedback]) return true;
  return checkExpiredDate(userFeedbacks[feedback]);
}

export function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function splitArray(array, length) {
  const result = [];
  for (let i = 0; i < array.length; i += length) {
    const chunk = array.slice(i, i + length);
    result.push(chunk);
  }
  return result;
}

export function validateModifiedAt(now, modifiedAt) {
  return now - modifiedAt > 1000 * 30;
}

export function getDriveMigrationStatistics(process) {
  const totalProgress = process.failed + process.transfered;
  return [
    {
      content: process.totalFiles.toString(),
      icon: "mdi-note-multiple",
      tipKey: "driveMigration.tips.batches.total",
      color: "accent",
    },
    process.status === "toDo" && {
      content: (process.totalFiles - totalProgress).toString(),
      icon: "mdi-note-multiple",
      tipKey: "driveMigration.tips.batches.missing",
      color: "grey",
    },
    {
      content: process.transfered.toString(),
      icon: "mdi-note-multiple",
      tipKey: "driveMigration.tips.batches.finished",
      color: "success",
    },
    {
      content: process.failed.toString(),
      icon: "mdi-note-multiple",
      tipKey: "driveMigration.tips.batches.failures",
      color: "error",
    },
  ].filter((value) => value);
}

export function containsText(text, searchText) {
  const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedSearchText, "i");
  return regex.test(text);
}

export function formatLink(link) {
  if (link && !link.startsWith("https://") && !link.startsWith("http://")) {
    return `https://${link}`;
  }
  return link || "";
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function batchesPartition(items, batchSize = 500) {
  const batches = {};
  for (let i = 0; i < items.length; i += batchSize) {
    const key = i + batchSize;
    batches[key] = items.slice(i, i + batchSize);
  }
  return batches;
}

export function createDatetimeWithDateAndHour(date, hour) {
  return moment(`${date} ${hour}`).toISOString();
}
