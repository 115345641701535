<template>
  <v-app v-resize="onResize" class="overflow-hidden">
    <Sidebar
      v-if="!editingTemplate && !userIsOnSelfCheckout"
      @executeRequestActivation="requestActivation"
    />

    <!-- NOTIFICATION SIDEBAR -->
    <NotificationsSidebar v-if="currentUser" :color="primaryColor" />

    <!-- ONLINE USERS SIDEBAR -->
    <OnlineUsersSidebar v-if="isConecta" />

    <!-- NAVBAR -->
    <Navbar
      @snackbarPurchaseInterest="requestActivation"
      @openSupport="openSupport"
      :users="users"
    />

    <v-main :width="bodyWidth" class="background overflow-hidden pb-0">
      <ResizablePage
        styles=""
        bodyStyle=""
        hide-custom-space
        :footer-height="0"
        hide-footer
        :resizable-max-width="false"
      >
        <template v-slot:header>
          <!-- Alerta Modo Reseller -->
          <v-banner
            v-if="isSimulation"
            sticky
            app
            :single-line="!$vuetify.breakpoint.smAndDown"
            :class="`${
              !$vuetify.breakpoint.smAndDown ? 'py-1' : ''
            } background`"
            icon="mdi-alert-circle-outline"
            icon-color="warning"
            style="height: auto; top: 0px"
          >
            Modo Parceiro - Você está gerenciando
            <span class="font-weight-bold primary--text">{{ mainDomain }}</span>
            <template v-slot:actions>
              <v-btn
                small
                width="100%"
                outlined
                @click="endSimulationMode"
                class="font-weight-bold"
                rounded
              >
                <v-icon left v-text="'mdi-arrow-left'" dense />Voltar
              </v-btn>
            </template>
          </v-banner>
        </template>

        <template v-slot:table="tableProps">
          <v-card
            color="background"
            flat
            tile
            class="overflow-y-auto ma-0 pa-0 rounded-0"
            :height="tableProps.tableHeight"
          >
            <!-- Notification kind Dialog
            <v-breadcrumbs v-if="hasBreadcrumbs" :items="breadcrumbs">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="item.to"
                  class="text-subtitle-2 font-weight-regular"
                  :disabled="item.disabled"
                  exact
                >
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs> -->
            <router-view />
          </v-card>
        </template>
      </ResizablePage>
    </v-main>

    <!-- LOCAL SNACKBAR -->
    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />

    <!-- GLOBAL SNACKBAR -->
    <Snackbar
      data-testid="snackbar-global"
      :show="isShowingSnackbar"
      :hasKey="snackbarHasMessageKey"
      :message_key="snackbarMessageKey"
      :message="snackbarMessage"
      :timeout="snackbarTimeout"
      :notShowCloseButton="snackbarShowCloseButton"
      :color="snackbarColor"
      @close="resetSnackbarInfo"
    />

    <GenerateMeetInvite v-if="isConecta" />

    <v-scroll-x-reverse-transition>
      <BannerMeetInvite v-if="showMeetInviteDialog" />
    </v-scroll-x-reverse-transition>

    <ActionsScheduleMeeting
      v-if="showScheduleMeeting && !isSimulation && !isAdminPage"
    />

    <HeaderDialog
      :show="attentionToPriceChange"
      id="new_product_price_editing"
      :title="$t('priceChangeWarning.priceAdjustment')"
      :subtitle="$t('priceChangeWarning.subtitle')"
      width="800"
      :showCloseBtn="false"
      :actionText="$t('priceChangeWarning.iUnderstood')"
      @action="priceChangeNoticed"
    >
      <template v-slot:body>
        <v-row class="mx-3 mb-2" no-gutters>
          <p class="mt-4">
            <span v-html="$t('priceChangeWarning.warning')"></span>

            <a
              href="https://conectasuite.com/precos/"
              target="_blank"
              class="text-body-1 text-decoration-none"
            >
              <span class="font-weight-bold blue--text"> Conecta Suite.</span>
            </a>
          </p>

          <p>{{ $t("priceChangeWarning.noActionNeeded") }}</p>

          <p>
            {{ $t("priceChangeWarning.willingToHelp") }}
            <a
              class="text-body-1 font-weight-bold text-decoration-none"
              href="mailto:comercial@conectasuite.com"
              ><span class="text-body-1 font-weight-bold blue--text">
                comercial@conectasuite.com</span
              ></a
            >.
          </p>

          <p>{{ $t("priceChangeWarning.thanks4BeeingClient") }}</p>
        </v-row>
      </template>
    </HeaderDialog>
  </v-app>
</template>

<script>
import ResizablePage from "@/components/base/ResizablePage";
import Navbar from "@/components/main/dashboard/Navbar.vue";
import Sidebar from "@/components/main/dashboard/Sidebar.vue";
import GenerateMeetInvite from "@/components/main/onlineUsers/GenerateMeetInvite.vue";
import BannerMeetInvite from "@/components/main/onlineUsers/BannerMeetInvite.vue";
import NotificationsSidebar from "@/components/main/notifications/NotificationsSidebar.vue";
import OnlineUsersSidebar from "@/components/main/onlineUsers/OnlineUsersSidebar";
import { links } from "@/helpers/variables/links";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import ActionsScheduleMeeting from "@/components/base/ActionsScheduleMeeting.vue";
import {
  getDatabase,
  ref,
  set,
  off,
  onValue,
  get,
  child,
} from "firebase/database";
import { today, dateIsToday } from "@/helpers/services/utils";
import {
  setPathDefaultHubSpotTrack,
  loadHubSpotTracking,
  setTrackingPathsHubspot,
} from "@/helpers/hubspot";
import {
  SUITE_DEV_PROJECT_NAME,
  SUITE_PROD_PROJECT_NAME,
} from "@/helpers/variables/backendConstants";
import { domainsToSeePriceChangeWarning } from "@/helpers/variables/domainsSeePriceChange";

export default {
  components: {
    NotificationsSidebar,
    Sidebar,
    Navbar,
    ResizablePage,
    ActionsScheduleMeeting,
    OnlineUsersSidebar,
    GenerateMeetInvite,
    BannerMeetInvite,
  },
  data: () => ({
    helpMenu: false,
    loadResellerTerm: false,
    showSnackbar: false,
    snackbarType: "success",
    snackbarText: "",
    showBlackFriday: false,
    bodyWidth: 0,
    links,
    previewedNavbarFeedbackButton: localStorage.getItem(
      "previewedNavbarFeedbackButton"
    ),

    // temporary warining
    attentionToPriceChange: false,
    domainsToSeePriceChangeWarning,
  }),

  computed: {
    ...mapGetters([
      "showMeetInviteDialog",
      "currentRoute",
      "hasBillingPermission",
      "primaryColor",
      "company",
      "showScheduleMeeting",
      "users",
      "currentUser",
      "hasAcceptedTerms",
      "isSimulation",
      "isConecta",
      "token",
      "tokenReseller",
      "snackbar",
      "isShowingSnackbar",
      "snackbarTimeout",
      "snackbarHasMessageKey",
      "snackbarMessage",
      "snackbarColor",
      "snackbarShowCloseButton",
      "snackbarMessageKey",
      "loading",
      "hasContractedPlans",
      "plansInTrialToShowSchedule",
      "feedbackButtonClick",
      "hasEndedTrial",
      "hasSuitePagePermission",
      "isSmallClient",
      "hasProcessRunning",
      "emailTransferInProgressPath",
    ]),

    isAdminPage() {
      return [
        "ResellerCustomers",
        "ResellerClientInfo",
        "ResellerNewPlan",
        "ResellerNewService",
        "ResellerBilling",
        "CentralNotifications",
        "BanClients",
        "ServicesManager",
        "PlansManager",
      ].includes(this.$route.name);
    },

    userIsOnSelfCheckout() {
      return (
        this.currentRoute === "BillingUpgradePlan" && this.hasBillingPermission
      );
    },

    isNewTemplateEditor() {
      return this.$route.name.includes("NewSignStyle");
    },

    editingTemplate() {
      return this.$route.name.includes("TemplateEdit");
    },

    feedbacksButtons() {
      return document.getElementsByClassName("feedback-button");
    },

    mainDomain() {
      return this.company.main_domain;
    },

    isConectaPartnerPage() {
      return (
        [
          "ResellerClients",
          "ResellerClientInfo",
          "ResellerNewPlan",
          "ResellerBilling",
          "ResellerNotifications",
        ].includes(this.$route.name) && !this.hasAcceptedTerms
      );
    },

    hasBreadcrumbs() {
      return this.$route.meta.breadCrumb ? true : false;
    },

    breadcrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    },

    hasMigrationRunning() {
      return this.hasProcessRunning || this.emailTransferInProgressPath;
    },

    isCompanyCreatedToday() {
      return this.dateIsToday(this.company.created_at);
    },
  },

  watch: {
    showBlackFriday() {
      if (!this.showBlackFriday) {
        this.showBlackFriday = false;
        localStorage.setItem("showBlackFriday", "false");
      }
    },

    "$route.path": async function () {
      if (
        !["ResellerNewPlan", "ResellerClientInfo"].includes(this.$route.name)
      ) {
        localStorage.removeItem("selectedResellerClient", "");
      }
      if (!this.isSimulation) {
        this.setTrackingPathsHubspot(this.$route.fullPath);
      }
    },

    feedbacksButtons(newValue, oldValue) {
      if (newValue.lenght !== oldValue.lenght) {
        this.removeFeedback();
        this.setFeedback();
      }
    },

    feedbackButtonClick() {
      this.showCollectorDialog();
    },
    hasMigrationRunning(value) {
      if (value) {
        window.addEventListener("beforeunload", this.tasksProcessesHandler);
        window.addEventListener("beforeunload", this.leaveDriveProcessRoom);
        window.addEventListener("unload", this.leaveDriveProcessRoom);
      } else {
        window.removeEventListener("beforeunload", this.tasksProcessesHandler);
        window.removeEventListener("beforeunload", this.leaveDriveProcessRoom);
        window.removeEventListener("unload", this.leaveDriveProcessRoom);
      }
    },
  },

  methods: {
    ...mapMutations([
      "setInviteResponse",
      "setSidebarView",
      "setLoading",
      "resetSnackbarInfo",
      "setOnlineUsers",
      "setConfigMeetInvite",
      "setCalendars",
      "setUserById",
      "setOnboardingStep",
      "setOffboardingStep",
      "setUsers",
      "setLocale",
    ]),

    tasksProcessesHandler(event) {
      event.preventDefault();
      event.returnValue = "";
    },

    ...mapActions([
      "getCompany",
      "getUsers",
      "purchaseInterest",
      "getScopes",
      "checkBillingPermission",
      "getPlans",
      "syncUos",
      "getUos",
      "leaveDriveProcessRoom",
      "getSyncUsersStatus",
    ]),

    dateIsToday,

    closeSupport() {
      this.helpMenu = false;
    },

    priceChangeNoticed() {
      this.setUserNoticedPriceChange();
      this.attentionToPriceChange = false;

      this.saveNoticedPriceChange();
    },

    setUserNoticedPriceChange() {
      const database = getDatabase();

      set(ref(database, `noticed_price_change/${this.currentUser.key}`), {
        email: this.currentUser.email,
        date: today.format("DD/MM/YYYY HH:mm:ss"),
        id_google: this.currentUser.id_google,
      });
    },

    checkIfUserAlreadySeenThePriceChange() {
      const dbRef = ref(getDatabase());

      get(child(dbRef, `noticed_price_change/${this.currentUser.key}`))
        .then((snapshot) => {
          const userSeenPriceChaged = snapshot.exists()
            ? snapshot.val()
            : false;

          if (!userSeenPriceChaged) {
            this.attentionToPriceChange = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    saveNoticedPriceChange() {
      const url = `${process.env.VUE_APP_BIG_QUERY_API}/save-noticed-price-changes`;
      const project_name =
        process.env.NODE_ENV !== "production"
          ? SUITE_DEV_PROJECT_NAME
          : SUITE_PROD_PROJECT_NAME;

      const { name, id_google, email } = this.currentUser;

      const payload = {
        project_name,
        app: "Conecta Suite",
        name,
        id_google,
        email,
        main_domain: this.mainDomain,
      };

      this.$axios
        .post(url, payload)
        .then(() => {})
        .catch((err) => {
          console.error("saveNoticedPriceChange()", err);
        })
        .finally(() => {});
    },

    openSupport() {
      if (this.$route.name != "ContactUs")
        this.$router.push({ name: "ContactUs" });
    },

    endSimulationMode() {
      localStorage.removeItem("company");
      localStorage.removeItem("allCalendars");
      localStorage.removeItem("userId");
      localStorage.removeItem("userSelected");
      localStorage.removeItem("userCreated");
      localStorage.removeItem("users");
      localStorage.removeItem("offboardUser");
      this.setCalendars({});
      this.setUserById({});
      this.setUsers([]);
      this.setOnboardingStep();
      this.setOffboardingStep();
      this.$router.push(`/auth?token=${this.tokenReseller}`);
    },

    loadJiraFeedbackIntegration() {
      var s = document.createElement("script");
      s.src =
        "https://conectanuvem.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=pt-BR&collectorId=12a150ca";
      document.body.appendChild(s);
    },

    // Init load HubSpot
    setPathDefaultHubSpotTrack,
    loadHubSpotTracking,
    setTrackingPathsHubspot,

    setFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function (showCollectorDialog) {
          _this.feedbacksButtons.forEach((element) => {
            element.addEventListener("click", (e) => {
              e.preventDefault();
              showCollectorDialog();
            });
          });
          _this.showCollectorDialog = showCollectorDialog;
        },
      };
    },

    showCollectorDialog() {},

    removeFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function () {
          _this.feedbacksButtons.forEach((element) => {
            element.removeEventListener("click", false);
          });
        },
      };
    },

    onResize() {
      this.bodyWidth = this.sidebarMini
        ? window.innerWidth - 230
        : window.innerWidth - 300;
    },

    async acceptResellerTerms() {
      this.loadResellerTerm = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/reseller/accept-terms`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      let params = {};
      await this.$axios
        .post(url, params, auth)
        .then(() => {
          this.getCompany();
          this.snackbarText = successMessages.partner_term;
          this.showSnackbar = true;
          this.snackbarType = "success";
        })
        .catch(() => {
          this.snackbarText = errorMessages.partner_term;
          this.showSnackbar = true;
          this.snackbarType = "error";
        });

      this.loadResellerTerm = false;
    },

    goToSelfCheckout() {
      this.showBlackFriday = false;
      localStorage.setItem("showBlackFriday", "false");
      this.$router.push("/billing/add-plan");
    },

    updateContract() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/contract`;
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios.put(url, {}, auth);
    },

    requestActivation(product) {
      this.checkBillingPermission()
        .then(() => {
          const analytics = getAnalytics();

          logEvent(analytics, "go_to_self_checkout", {
            click: "trial_bar",
            main_domain: this.mainDomain,
            email: this.currentUser.email,
            description:
              "Clicou no botão 'Contratar plano' do menu de trial na barra superior (navbar)",
          });

          this.$router.push("/billing/add-plan");
        })
        .catch(() => {
          this.snackbarType = "warning";
          this.snackbarText =
            "Acesso negado, para contratar um plano contate o seu administrador!";
          this.showSnackbar = true;
        })
        .finally(() => {
          // Envio de evento RD informando sobre interesse de aquisição de plano
          this.purchaseInterest(product)
            .then(() => {
              // this.textSuccess = "Solicitação realizada com sucesso!";
              // this.snackbarSuccess = true;
            })
            .catch(() => {
              this.textFailure = "Erro ao realizar a solicitação!";
              this.snackbarFailure = true;
            });
        });
    },

    setUserRTDB(online = false) {
      const db = getDatabase();
      if (this.isConecta) {
        const waitingRef = ref(db, `attendant/${this.currentUser.key}`);
        set(waitingRef, {
          user: JSON.parse(JSON.stringify(this.currentUser)),
          meetCall: {
            status: "",
          },
        });
      } else {
        const waitingRef = ref(db, `waiting/${this.currentUser.key}`);
        set(waitingRef, {
          user: JSON.parse(JSON.stringify(this.currentUser)),
          company: this.company,
          isTrial: !!this.plansInTrialToShowSchedule,
          lastAccess: new Date().toUTCString(),
          meetCall: {
            status: "",
            attendent: "",
            meetLink: "",
          },
          online,
        });
      }
    },

    setUserOffline() {
      this.setUserRTDB(false);
    },

    leaveWaitingRoom() {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting`);
      off(waitingRef);
    },

    enterWaitingRoom() {
      const db = getDatabase();
      const waitingRef = ref(db, `waiting`);
      onValue(waitingRef, this.waitingRoomConectaHandler);
    },

    waitingRoomConectaHandler(payload) {
      const data = payload.val() || {};
      const onlineUsersWithTrial = Object.values(data).filter(
        (user) => user.online && user.isTrial
      );
      const onlineUsersWithoutTrial = Object.values(data).filter(
        (user) => user.online && !user.isTrial
      );

      const sortedOnlineUsers = onlineUsersWithTrial.concat(
        onlineUsersWithoutTrial
      );

      this.setOnlineUsers(sortedOnlineUsers);
    },

    meetCallingHandler() {
      const db = getDatabase();

      if (this.isConecta) {
        const userRef = ref(db, `attendant/${this.currentUser.key}/meetCall`);
        onValue(userRef, (snapshot) => {
          const meetCallData = snapshot.val();
          if (
            ["accepted", "declined", "expired"].includes(meetCallData?.status)
          ) {
            this.setInviteResponse(meetCallData?.status);
          }
        });
      }

      const userRef = ref(db, `waiting/${this.currentUser.key}/meetCall`);

      onValue(userRef, (snapshot) => {
        const meetCallData = snapshot.val();
        if (meetCallData?.status === "receving_call") {
          const config = {
            attendentCalling: meetCallData.attendent,
            meetLink: meetCallData.meetLink,
            show: true,
          };
          this.setConfigMeetInvite(config);
        } else if (meetCallData?.status === "canceled") {
          const config = {
            attendentCalling: "",
            meetLink: "",
            show: false,
          };
          this.setConfigMeetInvite(config);
        }
      });
    },

    setPage() {
      const goToPurcharsePage =
        this.hasEndedTrial &&
        this.hasSuitePagePermission["billing"] &&
        !this.isSimulation &&
        this.$route.name !== "BillingUpgradePlan";

      if (goToPurcharsePage) {
        this.$router.push({ name: "BillingUpgradePlan" });
        return;
      }
    },

    saveLocale(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang == "pt-br" ? "pt" : lang;
      localStorage.setItem("lang", lang);
      this.setLocale(lang);
    },
  },

  async beforeMount() {
    this.setLoading(true);
    await this.getCompany().then(() => this.setPage());
    this.saveLocale(this.company.language);

    if (this.$vuetify.breakpoint.mobile) {
      this.setSidebarView(false);
    }
    this.getScopes();
    if (!this.company.uos) {
      this.syncUos();
    }
    if (this.isCompanyCreatedToday) this.getSyncUsersStatus();
    await Promise.all([
      this.loadJiraFeedbackIntegration(),
      this.getUsers(),
      this.getPlans(),
      this.getUos(),
    ]).then(() => {
      if (
        !this.isSimulation &&
        !this.company.contract &&
        this.currentUser.is_google_admin
      ) {
        this.updateContract();
      }
      if (this.isConecta) {
        this.enterWaitingRoom();
        this.setUserRTDB();
        window.addEventListener("beforeunload", this.leaveWaitingRoom);
        window.addEventListener("unload", this.leaveWaitingRoom);
      } else {
        this.setUserRTDB(true);
        window.addEventListener("beforeunload", this.setUserOffline);
        window.addEventListener("unload", this.setUserOffline);
      }
      this.setLoading(false);
    });

    if (this.isSimulation) {
      return;
    }

    if (!this.isSmallClient) {
      this.setPathDefaultHubSpotTrack();
      this.loadHubSpotTracking(this.currentUser);
    }

    if (this.domainsToSeePriceChangeWarning.includes(this.mainDomain)) {
      this.checkIfUserAlreadySeenThePriceChange();
    }
  },

  mounted() {
    this.setFeedback();
    this.meetCallingHandler();
  },

  beforeDestroy() {
    this.removeFeedback();
    this.setUserOffline();
    if (this.isConecta) {
      this.leaveWaitingRoom();
      window.removeEventListener("beforeunload", this.leaveWaitingRoom);
      window.removeEventListener("unload", this.leaveWaitingRoom);
    } else {
      window.removeEventListener("beforeunload", this.setUserOffline);
      window.removeEventListener("unload", this.setUserOffline);
      window.removeEventListener("beforeunload", this.leaveDriveProcessRoom);
      window.removeEventListener("unload", this.leaveDriveProcessRoom);
    }
    window.removeEventListener("beforeunload", this.tasksProcessesHandler);
  },
};
</script>

<style lang="scss" scoped>
.help-card {
  z-index: 100;
  position: fixed;
  right: 2%;
  top: 8%;
}

.support-card {
  z-index: 100;
  position: fixed;
  right: 4%;
  top: 6%;
}
</style>
