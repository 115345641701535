import { errorMessages } from "@/helpers/variables/snackbarMessages";

const state = () => ({
  domain_group: {},
  shifts: [],
});

const getters = {
  domainGroup: (state) => state.domain_group,
  controlShifts: (state) => state.shifts,
};

// actions
const actions = {
  async getControlCompany({ commit }) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/company`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios
      .get(url, config)
      .then(({ data }) => {
        commit("setControlCompany", data);
      })
      .catch((error) => {
        commit("setSnackBar", config);
        console.error("getControlCompany(): ", error);
      });
  },

  async getControlShifts({ getters, commit }) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/shifts`;

    const config = {
      headers: { Authorization: this._vm.token },
    };

    await this._vm.$axios
      .get(url, config)
      .then(({ data }) => {
        commit("setControlShifts", data);
      })
      .catch((error) => {
        const { data } = error.response;
        const didNotFindUserInControl = data.code == "USERS_NOT_FOUND";
        const {
          USER_NOT_FOUND_IN_CONTROL,
          SHIFTS_NOT_FOUND,
          VERIFY_CONTROL,
          SIGN_IN_CONTROL,
        } = errorMessages;
        const config = {
          color: "error",
          message: didNotFindUserInControl
            ? USER_NOT_FOUND_IN_CONTROL
            : `${SHIFTS_NOT_FOUND} ${
                getters.company.control_last_seen
                  ? VERIFY_CONTROL
                  : SIGN_IN_CONTROL
              }`,
          show: true,
        };

        commit("setSnackBar", config);
        console.error("getShifts(): ", error);
      });
  },

  async addUserToShifts(_, payload) {
    const url = `${process.env.VUE_APP_CONECTA_CONTROL_API_BASE_URL}/integrations/shifts/add-users-in-batch`;
    const auth = {
      headers: {
        Authorization: this._vm.token,
      },
    };

    return await this._vm.$axios.put(url, payload, auth);
  },
};

// mutations
const mutations = {
  setControlCompany(state, data) {
    state.domain_group = data.company;
  },
  setControlShifts(state, data) {
    const handle_obeys_ponto_mais_rules = (data) => {
      data.pontomais_login_rule = !!(
        data.pontomais_login_rule ||
        (data.obeys_ponto_mais_rules && data.advanced_auto_logout)
      );
      data.pontomais_auto_logout_rules =
        data.obeys_ponto_mais_rules && data.advanced_auto_logout
          ? [0]
          : data.pontomais_auto_logout_rules;
      return data;
    };

    data = data.map(handle_obeys_ponto_mais_rules);
    state.shifts = data;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
