export const common = {
  for: "for",
  hour: "Hour",
  insertLink: "Insert link",
  understood: "Got it",
  availableFromSecurity: "Available for plans starting from Security",
  pickADate: "Choose a date",
  and: "and",
  pending: "Pending",
  next: "Next",
  currentPlan: "Current plan",
  currentPlans: "Current plans",
  free: "Free",
  freeTrialPeriod: "Free (14 days)",
  includes: "Includes:",
  licenseNumber: "Number of licenses",
  recommended: "Recommended",
  user: "User",
  installment: "Installment",
  installments: "Installments",
  annualPlan: "Annual Plan",
  trialPlan: "Trial Plan",
  flexPlan: "Monthly Plan",
  freePlan: "Free Plan",
  active: "Ativo",
  total: "Total",
  licenses: "licenses",
  license: "License",
  to: "to",
  notRegistered: "Not registered",
  payment: "Payment",
  from: "From",
  until: "Until",
  since: "Since",
  second: "second",
  seconds: "seconds",
  help: "Help",
  requiredField: "Indicates a required field",
  start: "Start",
  createUser: "Create user",
  userInfos: "User Informations",
  emailSignatureInfomations: "E-mail signature informations",
  emailSignature: "E-mail signature",
  accessControl: "Access control",
  selectUser: "Select user",
  name: "Name",
  lastName: "Last Name",
  primaryEmail: "Primary Email",
  secondaryEmail: "Secondary Email",
  domain: "Domain",
  supportedFormats: "Supported formats",
  organizationUnit: "Organizational unit",
  image: "Image",
  back: "Back",
  advance: "Advance",
  group: "Group",
  prepare: "Prepare",
  representation: "Representation",
  addUserToGroups: "Add user to groups",
  workspaceGroups: "Workspace Groups",
  workspaceGroupsDescription:
    "Add the user to groups so that they can receive and send emails with the group email.",
  confirmDeletion: "Confirm deletion",
  confirmGroupDeletion: "Do you really want to remove the user from the group",
  addGroupDescription: "Select the group you want to add the user to",
  saveAsSecondaryEmail: "Save as user's secondary email",
  sendEmailAs: "send email as",
  groupAliasAlreadyInUseforUser:
    "This group is already a secondary email for this user.",
  requiredEmailService: "Valid only for users with an active email service!",
  inactiveUserInWorkspaceAlert:
    "This user's email service is not active! Consider verifying your license in Google Workspace.",
  inactiveEmailService: "This user's email service is not active",
  configAlternativeEmails: "Configure alternative emails",
  emailsAliases: "emails aliases",
  configAlternativeEmailsDescription:
    "Add alternative email addresses (email aliases) so that the user can receive messages from other email addresses.",
  receiveEmailsLike: "Receive emails like",
  addAlternativeEmail: "Add alternative email",
  confirmDeletionDescription: "Do you really want to delete",
  addAlternativeEmailDescription:
    "Enter the alternative email address where the user can receive messages.",
  alternativeEmail: "Alternative e-mail",
  configSecondaryEmail: "Configure secondary email",
  configSecondaryEmailDescription:
    "Add email addresses so that the user can use their default email to send messages from other email addresses.",
  defaultEmail: "Default e-mail",
  confirmAction: "Do you really want to confirm this action?",
  confirmChange: "Confirm change",
  confirmChanges: "Confirm changes",
  confirmModify: "Confirmar modify",
  enterNameFor: "Enter name for",
  descriptionConfirmModification: "Do you really want to modify",
  addEmailAddress: "Add email address",
  secondaryEmailAddressDescription:
    "Add email addresses so users can use their default email to send messages from other email addresses.",
  applySecondaryEmail: "Apply secondary e-mail",
  optional: "Optional",
  addSecondaryEmailDescription: "Enter the email address that users can use.",
  watchTheVideo: "Watch the video",
  customizedInformation: "Customized information",
  subscriptionApp: "Signature application",
  subscriptionAppTooltipText:
    "Email signatures will automatically be added to the end of Gmail messages, like a footer.",
  searchModel: "Search model",
  noCustomModelFound: "No custom templates found",
  noDefaultModelFound: "No default template found",
  noModelsSelected: "No models selected",
  selectAModel: "Select a model to view",
  currentSubscriptionTo: "Current signature to",
  subject: "Subject",
  checkTheNewSignature: "Check the new email signature!",
  viewInTheme: "View in theme",
  light: "light",
  dark: "dark",
  customized: "Customized",
  default: "Default",
  noDataAvailable: "No data available",
  selectedEmails: "Selected emails",
  lastUpdate: "Last update",
  loading: "Loading",
  noResults: "No results",
  linesPerPage: "Lines per page",
  userAddedToShifts: "added to shifts, onboarding complete!",
  insertUserIntoCalendar: "Insert user into Company calendars",
  userCalendars: "User Calendars",
  addUserCalendarsDescription:
    "Add the user to the Company's or other users' calendars so they can check events on the calendar.",
  schedule: "Schedule",
  description: "Description",
  addCalendar: "Add calendar",
  selectCalendars: "Select calendars to add to users.",
  addCalendarDescription:
    'User permissions (whether they can only view, add or modify events) are still configured on the <a href="https://calendar.google.com/">Google Calendar</a> website.',
  addCalendarsHelpText: "If an agenda is not available, update the list here",
  searchCalendars: "Search calendars",
  calendarsNotFound: "Company calendars were not founds",
  requestFunctionalityScopes:
    "Please accept the scopes in your Google Workspace to use this feature.",
  requestFunctionalityScopesToNonAdminUser:
    "Please ask your administrator to accept the scopes to use this functionality.",
  companyDrives: "Company Drives",
  addUserToSharedDrivers: "Add user to shared drives",
  addUserToSharedDriversDescription:
    "Add the user to drives so that they can access information about the company or sector to which they are linked.",
  addSharedDrive: "Add shared drive",
  addSharedDriveDescription:
    "Select the drive you want to insert the user into",
  companyDrive: "Company drive",
  defineTheRole: "Define the role",
  clickToCopyData: "Click here to go back and copy the data.",
  homePage: "Home page",
  viewInFullscren: "View in fullscren",
  noDataFound: "No data found",
  of: "of",
  viewingModel: "Viewing model",
  viewingModelAs: "Viewing model as",
  thisModelEmpty: "This template is empty",
  marketplaceInstall:
    "Conecta Suite is not installed in your Google Workspace, install it now to continue.",
  goToMarketplace: "Go to Marketplace",
  availableIndefinitely: "Available for a limited time!",
  conectaSign: "Conecta Sign",
  conectaTrack: "Conecta Track",
  conectaTransfer: "Conecta Transfer",
  conectaSuite: "Conecta Suite",
  conectaPartners: "Conecta Partners",
  usersPermissions: "Users permissions",
  contactUs: "Contact us",
  knowPlans: "Know the plans",
  scheduleMeeting: "Schedule a meeting",
  hello: "Hello",
  letsTalk: "let's talk?",
  concluded: "Finished",
  concludedWithFailures: "Finished with failures",
  unfinished: "Unfinished",
  resume: "Resume",
  failed: "Failed",
  unknown: "?",
  origin: "Origin",
  destination: "Destination",
  author: "Author",
  sharedDrive: "Shared Drive",
  theStart: "Start",
  theFinish: "Finish",
  soon: "Soon",
  status: "Status",
  date: "Date",
  unavailable: "Unavailable",
  unavailableOnYourPlan: "Unavailable for your plan",
  doNotHavePermission: "You don't have permission",
  feedbackRatingTitle: "Leave your feedback on the feature",
  reversibleAction: "The following action can be undone at any time.",
  irreversibleAction: "The following action cannot be undone.",
  exitWithoutSaving:
    "Some changes were not saved. Do you want to exit without saving?",
  sureToContinue: "Are you sure you want to confirm this action?",
  sureToContinueAction: "Are you sure you want <b>{action}</b>?",
  planDetails: "Plan details",
  serviceDetails: "Service Details",
  service: "Service",
  plan: "Plan",
  newPlan: "New plan",
  new: "New",
  upsellTitle: "Upgrade available for your company",
  checkPlans: "Discover Available Upgrades",
  assigned: "Assigned",
  removed: "Removed",
  tasks: "Tasks",
  running: "Running",
  finished: "Finished",
  closed: "Closed",
  pause: "Pause",
  userSelection: "User Selection",
  inventory: "Inventory",
  transferEmails: "Transfer Emails",
  transferDrive: "Transfer Drive",
  transferCalendar: "Transfer Calendar",
  licences: "Licenses",
  shortly: "Shortly",
  password: "Password",
  recipientEmail: "Recipient's email",
  choiceDateToSendEmail: "Choose a date to send the email",
  limitDate: "Limit of 30 days from today",
  dateToschedule: "Schedule date",
  sendCopyToMe: "Send a copy to me",
  sendEmail: "Send email",
  scheduleSend: "Schedule sending",
  notValidEmail: "Not a valid e-mail",
  roles: {
    organizer: "Organizer",
    fileOrganizer: "File organizer",
    writer: "Writer",
    commenter: "Commenter",
    reader: "Reader",
  },
  howToUseInformation: "How to use Information in your email signature.",
  email: "E-mail",
  saveOnlyDescription:
    "Save changes made to Information without reapplying the signature model for users using it",
  saveAndReapplyDescription:
    "Save changes and reapply signature template with updated information",
  userHasNoModelApplied:
    "This user does not have an applied model that can be reapplied.",
  editInGoogleDashboard: "Edit in Google",
  somethingWentWrong: "Something went wrong",
  appConnected: "Application connected",
  appDisconnected: "Application disconnected",
  disconnectApp: "Disconnect application",
  getLastAuth: "Get last authorization date",
  lastAuthDate: "Last authorization date",
  userAppsDisconnected:
    "The user {userName} has been disconnected from the following applications: {apps}",
  all: "All",
  usersSelecteds: "Users selecteds",
  details: "Details",
  customizedModel: "Custom model",
  defaultModel: "Default model",
  actionCanBeUndone: "This action can be undone at any time.",
  selected: "selected",
  users: "users",
  member: "Member",
  admin: "Administrator",
  anyType: "Any type",
  request: "Request",
  insert: "Insert",
  remove: "Remove",
  selectLatest1User: "Select at least 1 user to unlock options",
  selecteds: "selecteds",
  googleAdmin: "Google admin",
  lastSignatureApplied: "Last email signature applied to Conecta Suite",
  gmailUsage: "Gmail usage",
  gmailUsageDescription: "Do you have an active or inactive email box?",
  associatedName: "Associated name",
  emailServiceIsDisabled: "Email service is disabled for this user.",
  userRestrictDescription:
    "This user's email signature will only be changed upon confirmation by email.",
  usersRestrictedsDescription:
    "One or more restricted users have been selected. The signature will only be applied upon confirmation by email.",
  descriptionMakeUserRestricted:
    "After applying an email signature, restricted users will only have their signature changed upon approval by the user via confirmation email.",
  makeThisUserRestrict: "Do you want to make this user restricted?",
  removeRestrictionFromThisUser:
    "Do you want to remove the restriction from this user?",
  descriptionRemoveRestrictionFromThisUser:
    "Restricted users will only have their signature changed upon approval by the user via email after attempting to apply an email signature.",
  alertRemoveRestrictionFromThisUser:
    "Disabling the restriction will allow the user's signature to be changed at any time by any employee with access to Conecta Sign.",
  makeUserRestricted: "Make user restricted",
  removeUserRestriction: "Remove user restriction",
  type: "Type",
  primary: "Primary",
  secondary: "Secondary",
  userPrimaryEmail: "User primary email",
  userRestrictionChange: "User restriction change",
  requestUserUpdateInfo: "Request the user to update their information",
  viewAppliedSignature: "View applied signature",
  viewAppliedVacationMessage: "View vacation message applied",
  emailSignatureModelPreview: "Signature Model Preview",
  vacationModelPreview: "Vacation Model Preview",
  emptyTagInfo:
    "If there is <b>no information</b> the corresponding tag will be <b>blank</b>",
  bulkActionNotAvailable:
    "Bulk actions and email signatures are not available for your current plan, sign up now to use them.",
  availableOnleStandardPlan: "Available for Standard plans only",
  youDoNotHavePermissionEmail:
    "You do not have permission to manage email signatures.",
  requestAccessToAdmin: "Request the administrator to release your access!",
  customizeColumns: "Customize columns",
  customizeColumnsDescription:
    "Control the display of up to 8 columns simultaneously",
  moveColumnUp: "Move column up",
  moveColumnDown: "Move column down",
  toThe: "to the",
  notInformed: "Not informed",
  alertAboutChangePayment:
    "Please make sure the client has filled in the card information in Superlógica before changing the payment method. If you have any questions, please contact financeiro@conectasuite.com",
  aboutChangePayment:
    "This change does not generate any changes in Superlógica!",
  past7Days: "Past 7 days",
  past3Months: "Past 3 months",
  past6Months: "Past 6 months",
  external: "External",
  backToGmail: "Back to Gmail",
  userHasNoGroups: "This user doesn't belong to any Google Workspace group.",
  hasDriveScopePending:
    "This feature is not active due to pending scopes in the Conecta Suite application.",
  noUserPleaseSelect: "No user selected, please select at least one user.",
  selectStartEndDate: "Select start and end dates",
  startEndDate: "Start and end dates",
  enterInContact: "Enter in contact",
  vacationMessages: "Vacation message",
  activated: "ACTIVATED",
  deactivated: "DEACTIVATED",
  seeInfo: "See information",
  models: "Templates",
  seePlan: "See plan",
};
