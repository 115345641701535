<template>
  <v-app-bar
    :color="`background`"
    clipped-left
    clipped-right
    flat
    app
    :class="`px-0`"
    :style="'border-bottom: 4px solid #311f50 !important'"
    height="80"
  >
    <!-- TOOLBAR -->
    <SignSidebarMenu />

    <EditTemplateSettings :template="selectedTemplate" />

    <!-- TODO: mostrar somente quando houver alterações mesmo -->
    <!-- <v-slide-x-transition>
      <span
        v-if="canSave && $vuetify.breakpoint.smAndUp"
        class="font-italic caption pt-1 ml-1 secondary--text text--lighten-2"
      >
        Alterações não salvas
      </span>
    </v-slide-x-transition> -->

    <v-spacer />

    <!-- TODO: implementar a lógica de cores de um modelo -->
    <!-- <SelectDefaultColorsMenu v-if="!$vuetify.breakpoint.xs" /> -->

    <v-spacer />

    <!-- TODO: implementar a troca de editores -->
    <v-menu
      bottom
      offset-y
      left
      v-if="$vuetify.breakpoint.mdAndUp"
      width="300"
      :disabled="loading"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          outlined
          x-large
          elevation="0"
          class="text-none text-body-1 font-weight-medium px-8 rounded-pill mr-4"
          :loading="loading"
        >
          <v-icon
            v-text="'mdi-square-edit-outline'"
            size="22"
            left
            class="mr-3"
          />
          Opções do editor
          <v-icon v-text="'mdi-chevron-down'" right class="ml-3" />
        </v-btn>
      </template>

      <v-list width="300" class="py-0">
        <v-subheader> Alternar modo de edição </v-subheader>

        <v-list-item
          v-for="(editor, i) in editorTypes"
          :key="i"
          link
          class="font-weight-medium align-start"
          :disabled="editor.disabled"
          @click="setChangeEditorConfirmation(editor.value)"
        >
          <v-list-item-action class="mr-5">
            <v-icon v-text="editor.icon" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-wrap break-word">
              {{ editor.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="caption text-weight-regular text-wrap break-word mt-1 normal-line-height"
            >
              {{ editor.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />
      </v-list>
    </v-menu>

    <!-- BOTÃO DE SALVAR -->
    <v-menu
      offset-y
      left
      width="320"
      :disabled="!canSave"
      :open-on-hover="true"
      :nudge-right="-5"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          x-large
          elevation="0"
          class="text-none text-body-2 text-sm-body-1 font-weight-medium px-8 rounded-pill"
          :loading="loading"
          :disabled="!canSave"
        >
          <v-icon v-text="'mdi-content-save'" size="22" left class="mr-3" />
          Salvar
          <v-icon v-text="'mdi-chevron-down'" right class="ml-3" />
        </v-btn>
      </template>

      <v-list width="320" class="py-0">
        <v-list-item
          v-for="(editor, i) in saveOptions"
          :key="i"
          class="font-weight-medium py-2 px-6"
          @click.stop="selectSaveMethod(editor.value)"
        >
          <v-list-item-icon class="mr-4">
            <v-icon v-text="editor.icon" color="accent" size="28" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap break-word text-body-2 text-sm-body-1 font-weight-medium"
            >
              {{ editor.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-wrap break-word caption text-sm-body-2 mt-1 normal-line-height"
            >
              {{ editor.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <ConfirmationDialog
      v-if="selectedTemplateEditor && newEditor"
      :show="showEditorChangeConfirmation"
      action-text="Trocar de editor"
      :loading="loading"
      width="700"
      @close="showEditorChangeConfirmation = false"
      @ok="changeEditor(newEditor)"
      hide-reversible-text
      dense
      :custom-body-class="'pa-0 ma-0'"
    >
      <v-card-text class="normal-line-height text-body-1 pa-0 ma-0 my-5">
        Ao realizar a troca de editor de
        {{ editorNames[selectedTemplateEditor] }} para
        {{ editorNames[newEditor] }},
        <b>
          não será possível voltar a utilizar o editor atual
          {{ editorNames[selectedTemplateEditor] }} </b
        >para editar este modelo e suas duplicatas.
      </v-card-text>

      <v-img contain width="700" :src="editorChangeImage" />

      <v-card-text class="normal-line-height text-body-1 pa-0 ma-0 mt-5">
        Deseja continuar?
      </v-card-text>
    </ConfirmationDialog>

    <v-progress-linear
      v-if="loading"
      indeterminate
      absolute
      bottom
      color="white"
    />
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { hasChanges } from "@/helpers/services/utils";

/* import SelectDefaultColorsMenu from "@/components/sign/new_users_signature/SelectDefaultColorsMenu.vue"; */
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import SignSidebarMenu from "@/components/sign/new_users_signature/SignSidebarMenu.vue";
import EditTemplateSettings from "@/components/sign/new_users_signature/EditTemplateSettings.vue";
/* import EditorModeSwitch from "@/components/sign/new_users_signature/EditorModeSwitch.vue"; */

export default {
  name: "SignEditorNavbar",

  components: {
    /* SelectDefaultColorsMenu, */
    ConfirmationDialog,
    SignSidebarMenu,
    EditTemplateSettings,
    /* EditorModeSwitch, */
  },

  data() {
    return {
      editorNames: {
        drag_and_drop: "Arrasta e Solta",
        rich_text: "Rich Text",
        html: "HTML",
      },

      editorChangeImageKeys: {
        rich_text: require("@/assets/images/troca_para_editor_rich_text.png"),
        html: require("@/assets/images/troca_para_editor_html.png"),
      },

      newEditor: "",

      saveOptions: [
        {
          icon: "mdi-check-circle-outline",
          title: "Salvar apenas alterações",
          description: "Salvar as alterações sem realizar aplicação",
          value: "save_only",
        },
        {
          icon: "mdi-account-arrow-right-outline",
          title: "Salvar e aplicar",
          description:
            "Salvar modelo e aplicar modelo atualizado para usuários",
          value: "save_and_apply",
          disabled: true,
        },
      ],

      showEditorChangeConfirmation: false,
    };
  },

  computed: {
    ...mapGetters([
      "isConecta",
      "isDev",
      "currentUser",
      "loading",
      "company",
      "selectedTemplate",
      "selectedTemplateId",
      "selectedTemplateNewHtml",
      "selectedTemplateEditor",
    ]),

    canSave() {
      return hasChanges(
        this.selectedTemplate.html,
        this.selectedTemplateNewHtml
      );
    },

    editorChangeImage() {
      return this.newEditor ? this.editorChangeImageKeys[this.newEditor] : "";
    },

    editorTypes() {
      return [
        {
          icon: "mdi-pencil-ruler-outline",
          title: "Editor Rich Text",
          description:
            "Utilize um editor estilo documento para personalizar seu modelo",
          value: "rich_text",
          show: this.selectedTemplateEditor !== "rich_text",
        },
        {
          icon: "mdi-xml",
          title: "Editor HTML",
          description: "Personalize seu modelo utilizando HTML puro",
          value: "html",
          show: this.selectedTemplateEditor !== "html",
        },
      ].filter((item) => item.show);
    },
  },

  methods: {
    ...mapActions(["updateSignatureTemplate"]),

    ...mapMutations(["setReaplySignatureDialog", "setTemplateEditorLoading"]),

    async changeEditor(editor) {
      this.setTemplateEditorLoading(true);

      let payload = this.getPayload();

      payload.editor = editor;

      if (payload.editor) {
        this.showEditorChangeConfirmation = false;

        await this.updateSignatureTemplate(payload);

        if (editor === "rich_text") {
          this.$router.replace(`/sign/template/${this.selectedTemplateId}`);
        }
      }

      this.setTemplateEditorLoading(false);

      this.newEditor = "";
    },

    getPayload() {
      if (this.selectedTemplate) {
        let { id, name, description, editor } = this.selectedTemplate;

        return {
          id_template: id,
          html: this.selectedTemplateNewHtml,
          name,
          description,
          editor,
        };
      }
      return false;
    },

    save() {
      this.setTemplateEditorLoading(true);

      const payload = this.getPayload();

      if (payload) {
        this.updateSignatureTemplate(payload);
      }

      this.setTemplateEditorLoading(false);
    },

    selectSaveMethod(saveMethod) {
      if (saveMethod === "save_only") {
        this.save();
      } else {
        this.setReaplySignatureDialog(true);
      }
    },

    setChangeEditorConfirmation(new_editor) {
      this.newEditor = new_editor;

      if (this.selectedTemplateEditor === "drag_and_drop") {
        this.showEditorChangeConfirmation = true;
      } else {
        this.changeEditor(new_editor);
      }
    },
  },
};
</script>
<style scoped></style>
